import { Link } from "react-router-dom"

export default function DepositsBanner() {
    return(
        <>
            <div className="banner_wrapper deposit_wrapper cross_banner invest_banner">
                {/* <video autoPlay loop muted playsInline preload="auto" poster="">
                    <source src="https://frontend.development-env.com/azarma-videos/invest.mp4" type="video/mp4" />
                </video> */}
                <div className="container">
                    <section>
                        <h1 className="animate__animated animate__fadeInUp">Empower Your Investment Journey with Azamra</h1>
                        <h2 className="animate__animated animate__fadeInUp">Be a Part of Africa’s Future Growth</h2>
                        <p className="animate__animated animate__fadeInUp">Ready to invest back home? Whether purchasing land, buying a home, or supporting local businesses, Diaspora Invest turns your aspirations into reality. Reconnect with your roots and make a meaningful impact today!</p>
                        <Link to="/contact" className="header_btn animate__animated animate__bounceIn">Start Investing</Link>
                    </section>
                </div>
            </div>
        </>
    )
}