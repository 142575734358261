import { NavLink, useLocation  } from "react-router-dom";
import "./header.scss"
import logoImage from "../../assets/images/logo.svg"
import angleDown from "../../assets/images/angle-down.svg"
import { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import GoogleTranslate from "./GoogleTranslate";

export default function HeaderNav() {
    const [showSubMenu, setSubShowMenu] = useState(false);
    const { pathname } = useLocation();
    const isActive = ['/deposits', '/loans', '/diaspora-invest', '/digital-wallets'].includes(pathname);

    return(
        <>
            <div className="container fluid flex justify-between items-center">
                <NavLink to="/"><img src={logoImage} alt="logo"/></NavLink>
                <div className="navigation_wrap">
                    <ul className="list-none flex items-center">
                        <li><NavLink exact activeClassName="active" to="/">Home</NavLink></li>
                        <li><NavLink activeClassName="active" to="/about-us">About Us</NavLink></li>
                        <li><NavLink activeClassName="active" to="/cross-border-payments">Cross Border Payments</NavLink></li>
                        <li>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setSubShowMenu(false);
                                }}
                            >
                                <NavLink
                                    onClick={()=> setSubShowMenu(!showSubMenu)}
                                    to="javascript:void(0)"
                                    className={`
                                        ${isActive ? "active" : ''}
                                        ${showSubMenu ? "open_drop" : ''}
                                    `}
                                >
                                    Our Products
                                    <img src={angleDown} alt="angleDown"/>
                                </NavLink>
                                {showSubMenu &&
                                    <div className="navigation_drop">
                                        <NavLink
                                            activeClassName="active"
                                            onClick={()=> {
                                                setSubShowMenu(false)
                                            }}
                                            to="/deposits"
                                        >
                                            Deposits
                                        </NavLink>
                                        <NavLink
                                            activeClassName="active"
                                            onClick={()=> {
                                                setSubShowMenu(false)
                                            }}
                                            to="/diaspora-invest"
                                        >
                                            Diaspora Invest
                                        </NavLink>
                                        <NavLink
                                            activeClassName="active"
                                            onClick={()=> {
                                                setSubShowMenu(false)
                                            }}
                                            to="/loans"
                                        >
                                            Loans
                                        </NavLink>
                                        <NavLink
                                            activeClassName="active"
                                            onClick={()=> {
                                                setSubShowMenu(false)
                                            }}
                                            to="/digital-wallets"
                                        >
                                            Digital Wallets
                                        </NavLink>
                                    </div>
                                }
                            </OutsideClickHandler>
                        </li>
                        <li><NavLink activeClassName="active" to="/contact">Contact Us</NavLink></li>
                        <li><GoogleTranslate/></li>
                    </ul>
                </div>
                <div className="navigation_right">
                    <ul className="list-none flex items-center">
                        {/* <li><NavLink to="#">Login</NavLink></li> */}
                        <li><NavLink to="#" className="header_btn">Login</NavLink></li>
                    </ul>
                </div>
            </div>
        </>
    )
}