export default function DigitalBanner() {
    return(
        <>
            <div className="banner_wrapper deposit_wrapper cross_banner">
                <video autoPlay loop muted playsInline preload="auto" poster="">
                    <source src="https://azamra.com/videos/loan.mp4" type="video/mp4" />
                </video>
                <div className="container">
                    <section className="animate__animated animate__fadeInUp">
                        <h1>Unlock Your Business Potential with Azamra Loans</h1>
                        <p style={{marginBottom: "0"}}>We have an array of loan options that might be just what you need:</p>
                    </section>
                </div>
            </div>
        </>
    )
}