import React, { useEffect, useState } from "react";
import enFlag from "../../assets/images/en.jpg";
import frFlag from "../../assets/images/cameroon-flag.jpg";

const GoogleTranslate = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [isOtherDivVisible, setIsOtherDivVisible] = useState(false);

    // Google Translate initialization
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: "en,fr",
                autoDisplay: true,
                onLanguageChanged: function (lang) {
                    setSelectedLanguage(lang);
                    updateDropdownText(lang);

                    // Refresh page after a short delay
                    setTimeout(() => {
                        window.location.replace(window.location.href);
                    }, 100);
                }
            },
            "google_translate_element"
        );

        // Delay updating dropdown text to ensure rendering
        setTimeout(updateDropdownText, 1000);
    };

    // Observe language change
    const observeLanguageChange = () => {
        setTimeout(() => {
            const translateDropdown = document.getElementById('google_translate_element');
            if (!translateDropdown) return;
    
            const observer = new MutationObserver(() => {
                const dropdown = document.querySelector('.goog-te-combo');
                if (dropdown) {
                    const selectedLang = dropdown.value;
                    setIsOtherDivVisible(selectedLang === 'fr');
                }
            });
    
            observer.observe(translateDropdown, { childList: true, subtree: true });
    
            return () => observer.disconnect();
        }, 2000); // Delay to allow elements to render
    };
    

    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        addScript.async = true;
        document.body.appendChild(addScript);
    
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    includedLanguages: "en,fr",
                    autoDisplay: false
                },
                "google_translate_element"
            );
        };
    }, []);


    useEffect(() => {
        setTimeout(() => {
            observeLanguageChange();
        }, 2000); // 2-second delay to ensure element exists
    }, []);
    
    

    const updateDropdownText = (selectedLang) => {
        const dropdown = document.querySelector('.goog-te-combo');
        if (dropdown && dropdown.options.length >= 2) { // Ensure options exist
            dropdown.options[0].text = "ENG";
            dropdown.options[1].text = "FR";
    
            if (selectedLang) {
                dropdown.value = selectedLang;
                dropdown.dispatchEvent(new Event('change'));
            }
        }
    };
    

    return (
        <div className="d-flex flag-drop">
            <img src={isOtherDivVisible ? frFlag : enFlag} alt="flag" />
            <div id="google_translate_element"></div>
        </div>
    );
};

export default GoogleTranslate;
