import { Link } from "react-router-dom"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function StartSaving() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="start_saving_wrap">
                <div className="container">
                    <div className="start_saving diaspora-invest-img" data-aos="fade-up">
                        <section>
                            <h2>Get Started with Diaspora Invest Today</h2>
                            <p><strong>Ready to start an awesome investment journey?</strong> If you’re into land, real estate, farming, or business stuff, we’ve got solutions that are made for your success.</p>
                            <p>Don’t just dream about investing back home—<strong>make it a reality now</strong>. Join the Diaspora Invest movement and empower your community today!</p>
                            <p style={{marginBottom: "0"}}><Link to="/contact" style={{textDecoration: "underline"}}><b>Contact Us</b></Link> to unlock the potential of your homeland.</p>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}