import DepositeServe from "./deposite-serve/DepositeServe";
import DepositsBanner from "./deposits-banner/DepositsBanner";
import StartSaving from "./start-saving/StartSaving";
import WhyChoose from "./why-choose/WhyChoose";

export default function DiasporaInvest() {
    return(
        <>
            <DepositsBanner/>
            <WhyChoose/>
            <DepositeServe/>
            <StartSaving/>
        </>
    )
}