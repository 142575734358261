import depositeServe1 from "../../../assets/images/investmentimg.jpg"
import depositeServe2 from "../../../assets/images/agriculture.jpg"
import depositeServe3 from "../../../assets/images/lre.jpg"
import loan3 from "../../../assets/images/support.jpg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function DepositeServe() {
    useEffect(() => {
        AOS.init({
          duration: 1200,
          once: true, // Animation duration
        });
      }, []);
    const depositeServeList = [
        {
            image: depositeServe1,
            title: "Our Investment Options",
            minTitle: "Land and Real Estate: Secure Your Dream Property",
            description: "Looking for prime land or your ideal home? Our real estate options offer:",
            listContent: [
                {
                    listTitle: "Diverse Choices:",
                    listDes: "Invest in both urban and rural properties.",
                },
                {
                    listTitle: "Investment Security:",
                    listDes: "Your investment is protected.",
                },
            ],
            destwo: "Explore land and real estate to secure your dream property today!"
        },
        {
            image: depositeServe2,
            title: "Agriculture Projects: Cultivate Sustainable Growth",
            description: "Want to support local farming? Invest in agriculture initiatives that offer:",
            listContent: [
                {
                    listTitle: "Economic Potential:",
                    listDes: "Contribute to sustainable farming practices.",
                },
                {
                    listTitle: "Community Impact:",
                    listDes: "Help improve food security and local economies.",
                },
            ],
            destwo: "Join our agriculture projects and cultivate a brighter future for your homeland."
        },
        {
            image: depositeServe3,
            title: "Business Ventures: Fuel Local Innovation",
            description: "Interested in supporting local businesses? Our business investment options provide:",
            listContent: [
                {
                    listTitle: "Partnership Opportunities:",
                    listDes: "Collaborate with SMEs and innovative startups.",
                },
                {
                    listTitle: "Economic Growth:",
                    listDes: "Contribute to job creation and industry development.",
                },
            ],
            destwo: "Invest in dynamic business ventures and help shape the future of local industries!"
        }
    ]
    return(
        <>
            <div className="deposite_serve_wrapper">
                <div className="container">
                    {
                        depositeServeList.map(({image,title,minTitle,description,listContent,destwo},index)=>{
                            return(
                                <div className="deposite_serve_content" key={index}>
                                    <figure data-aos="fade-left"><img src={image} alt="img"/></figure>
                                    <section data-aos="fade-right">
                                        <h2>{title}</h2>
                                        <h3>{minTitle}</h3>
                                        <p className="first">{description}</p>
                                        <ul className="list-none">
                                            {
                                                listContent.map((content, index)=>{
                                                    return(
                                                        <li key={`a-${index}`}>
                                                            <span>{content.listTitle}</span> {content.listDes}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <p>{destwo}</p>
                                    </section>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="container">
                <div className="why_azamra_content depcontact">
                    <section>
                        <h2>Make a Difference Back Home</h2>
                        <h3>Invest with Purpose and Impact</h3>
                        <p>Your investment not only builds your wealth; it creates jobs, improves local infrastructure, and helps with sustainable development. With Diaspora Invest, what you invest doesn’t just help you grow; it empowers your whole community and country.</p>
                    </section>
                </div>

                <div className="loan_heading justify-center" style={{marginBottom: "30px"}} data-aos="fade-up">
                    <section>
                        <h2 style={{marginBottom: "0"}}>How We Support Your Investment Journey</h2>
                    </section>
                </div>
                <div className="loan_option_content">
                    <img src={loan3} alt="loan" data-aos="fade-right"/>
                    <ul className="list-none" data-aos="fade-left">
                        <li><span>Personalized Consultation:</span> Get tailored advice to find opportunities that align with your goals.</li>
                        <li><span>Legal and Regulatory Guidance:</span> We navigate local laws to protect your investments.</li>
                        <li><span>Market Insights:</span> Access research and analysis to identify promising sectors.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}