import "./depositsBanner.scss"
import { Link } from "react-router-dom"

export default function DepositsBanner() {
    return(
        <>
            <div className="banner_wrapper deposit_wrapper cross_banner">
                <video autoPlay loop muted playsInline preload="auto" poster="">
                    <source src="https://azamra.com/videos/deposit-video.mp4" type="video/mp4" />
                </video>
                <div className="container">
                    <section>
                        <h1 className="animate__animated animate__fadeInUp">Unlock Your Savings Potential with Azamra</h1>
                        <p className="animate__animated animate__fadeInUp">Ready to take control of your finances? Whether you need a safe account for your cash, want to earn more from it, or are saving up for something special, we’ve got you covered. Explore our deposit options and start your journey to financial success today!</p>
                        <Link to="/contact" className="header_btn animate__animated animate__bounceIn">Start Saving</Link>
                    </section>
                </div>
            </div>
        </>
    )
}