
import CrossBanner from "./cross-banner/CrossBanner";
import CrossBenefits from "./cross-benefits/CrossBenefits";
import CrossChoose from "./cross-choose/CrossChoose";
import CrossSaving from "./cross-saving/CrossSaving";

export default function CrossBorderPayments() {
    return(
        <>
            <CrossBanner/>
            <CrossChoose/>
            <CrossBenefits/>
            <CrossSaving/>
        </>
    )
}