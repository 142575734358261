import { Link } from "react-router-dom"
import "./digital.scss"
import digitalImg1 from "../../../assets/images/digital-img1.svg"
import digitalImg2 from "../../../assets/images/digital-img2.svg"
import digitalImg3 from "../../../assets/images/digital-img3.svg"
import digitalImg4 from "../../../assets/images/digital-img4.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Digital() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="digital_wrapper">
                <div className="container">
                    <div className="title_wrap" data-aos="fade-up">
                        <h2>Digital Products for the African Community</h2>
                    </div>
                    <ul className="list-none">
                        <li data-aos="fade-up">
                            <div className="digital_content">
                                <section>
                                    <h3>Deposits</h3>
                                    <h4>Start saving now with our deposit accounts.</h4>
                                    <p>Secure your funds and enjoy flexible savings with Azamra’s convenient deposit options, which include term deposit, non-term deposit and home savings.</p>
                                    <Link to="/deposits" className="simple_btn">Learn More</Link>
                                </section>
                                <img src={digitalImg1} alt="digitalImg1" />
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="digital_content">
                                <section>
                                    <h3>Loans</h3>
                                    <h4>Loans for MSMEs</h4>
                                    <p>Azamra offers loan options for MSMEs, such as asset financing, working capital loans, and trade finance. We help businesses grow and succeed.</p>
                                    <Link to="/loans" className="simple_btn black">Learn More</Link>
                                </section>
                                <img src={digitalImg2} alt="digitalImg2" />
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="digital_content">
                                <section>
                                    <h3>Digital Wallets</h3>
                                    <h4>Enhance payments with QR, NFC, and mPOS.</h4>
                                    <p>Our digital wallets enable businesses and their customers to transact effortlessly and seamlessly. Our ecosystem simplifies local transactions and promotes greater financial inclusion.</p>
                                    <Link to="/digital-wallets" className="simple_btn">Learn More</Link>
                                </section>
                                <img src={digitalImg3} alt="digitalImg3" />
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="digital_content">
                                <section>
                                    <h3>Cross-Border Payments</h3>
                                    <h4>Faster, Cheaper Cross-Border Payments.</h4>
                                    <p>Azamra leverages blockchain and stablecoins for fast, cost-effective cross-border payments, offering a smoother experience than traditional banks.</p>
                                    <Link to="/cross-border-payments" className="simple_btn black">Learn More</Link>
                                </section>
                                <img src={digitalImg4} alt="digitalImg4" />
                            </div>
                        </li>
                        {/* <li data-aos="fade-up">
                            <div className="digital_content">
                                <section>
                                    <h3>Diaspora Invest</h3>
                                    <h4>Empower Your Investment Journey</h4>
                                    <p>Ready to invest back home? Whether purchasing land, buying a home, or supporting local businesses, Diaspora Invest turns your aspirations into reality. Reconnect with your roots and make a meaningful impact today!</p>
                                    <Link to="/diaspora-invest" className="simple_btn">Learn More</Link>
                                </section>
                                <img src={digitalImg3} alt="digitalImg3" />
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}