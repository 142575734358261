import { Link } from "react-router-dom";
import addUser from "../../../assets/images/add-user.svg"

export default function CrossBanner() {
    return (
        <>
            <div className="banner_wrapper deposit_wrapper cross_banner">
                <video autoPlay loop muted playsInline preload="auto" poster="">
                    <source src="https://azamra.com/videos/media.mp4" type="video/mp4" />
                </video>
                <div className="container">
                    <section>
                        <h1 className="animate__animated animate__fadeInUp">Seamless Cross-Border <br />Payments in Minutes</h1>
                        <h2 className="animate__animated animate__fadeInUp">Streamline Your Global Transactions with Azamra</h2>
                        <p className="animate__animated animate__fadeInUp">Change how you handle international payments and experience the benefits of fast, cost-effective solutions with Azamra. Our team is here to assist you in using our technology to streamline your business operations and expand globally</p>
                        <Link href="/" className="header_btn">Sign up with Azamra today <img src={addUser} alt="add-user" /></Link>
                    </section>
                </div>
            </div>
        </>
    )
}