import "./startSaving.scss"
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function StartSaving() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="start_saving_wrap">
                <div className="container">
                    <div className="start_saving diaspora-invest-img" data-aos="fade-up">
                        <section>
                            <h2>Start Saving Today</h2>
                            <p>Looking to grow your savings? Whether you’re interested in a Term Deposit, Non-Term Deposit, or Home Savings account, Azamra has the perfect option for you. We’re here to support you every step of the way.</p>
                            <Link to="/" className="header_btn">Open an Account</Link>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}