import { Link } from "react-router-dom"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function CrossSaving() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    return(
        <>
            <div className="start_saving_wrap">
                <div className="container">
                    <div className="start_saving diaspora-invest-img" data-aos="fade-up">
                        <section>
                            <h2>Empower Your Business with Azamra</h2>
                            <h3>Apply Today and Start Your Journey to Success!</h3>
                            <p>Experience Cheaper, faster and more accessible cross-border payment solutions with Azamra. Pay oversea school fees and supplies.</p>
                            <Link to="/" className="header_btn">Start Your Application</Link>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}