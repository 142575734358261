import piggyBank from "../../../assets/images/dio.svg"
import percentage from "../../../assets/images/tyct.svg"
import secure from "../../../assets/images/abam.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WhyChoose() {
    useEffect(() => {
        AOS.init({
          duration: 1200, // Animation duration
          once: true,
        });
      }, []);
    const whyChooseList = [
        {
            icon: piggyBank,
            title: "Diverse Investment Opportunities ",
            description: "Find a variety of secure investment opportunities that bolster your financial portfolio in your home country."
        },
        {
            icon: percentage,
            title: "Transparency You Can Trust",
            description: "Every transaction is secure, ensuring full transparency throughout your investment journey."
        },
        {
            icon: secure,
            title: "Africa-Based Asset Managers",
            description: "Gain insights and guidance from local market specialists to help you make informed decisions."
        }
    ]
    return(
        <>
            <div className="choose_wrapper" style={{paddingTop: "0"}}>
                <div className="container">
                    <div className="title_wrap">
                        <h2>Why Choose Diaspora Invest?</h2>
                    </div>
                    <ul className="list-none grid-container-3">
                        {
                            whyChooseList.map(({icon,title,description},index)=>{
                                return(
                                    <li key={index}>
                                        <section>
                                            <figure><img src={icon} alt="piggy-bank"/></figure>
                                            <h3>{title}</h3>
                                            <p>{description}</p>
                                        </section>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}